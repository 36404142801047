import React from 'react';
import { MDBContainer, MDBRow, MDBBtn } from 'mdbreact';
import './announcement.scss';
import { FirebaseContext } from '../Firebase';
import AnnouncementItem from './AnnouncementItem';
import AddAnnounce from './AddAnnounce';
class AnnounceAdminList extends React.Component {
    static contextType = FirebaseContext

    state = {
        modal: false,
        load: true,
        edit: false,
        editid: null,
        form: {
            active: true,
            title: "",
            url: "",
            error: null
        },
        items: null
    }
    toggle = () => {
        //debugger;
        const editstate = this.state.editid ? false : false;
        this.setState({
            modal: !this.state.modal,
            edit: editstate,
            editid: null,
            form: {
                active: this.state.form.active,
                title: "",
                url: "",
                error: null
            }
        });
        //console.log(this.state);
    }

    afterAdd = (action) => {
        //debugger;
        //console.log(action);
        //console.log(this.state);
        if (action === "delete") {
            this.setState({ load: !this.state.load });
        } if (action === "edit") {
            //console.log(this.state);
            this.setState({ load: !this.state.load, modal: false, edit: false, editid: null, form: { active: true, title: "", url: "", error: null } });
            //console.log(this.state);
        } if (action === "status") {
            this.setState({ load: !this.state.load, edit: false, editid: null, form: { active: true, title: "", url: "", error: null } });
        }
        else {
            this.setState({ load: !this.state.load, modal: !this.state.modal, edit: false, editid: null, form: { active: true, title: "", url: "", error: null } });
        }
    }
    getlist = () => {
        const { firebase, loading } = this.context;
        if (!loading) {
            if (this.state.load) {
                firebase.db.collection("shoutouts").get().then(querySnapshot => {
                    //console.log(querySnapshot.docs);
                    ////debugger;
                    const data = querySnapshot.docs.map(doc => {
                        const datadoc = { id: doc.id, ...doc.data() };
                        return datadoc;
                    });
                    //debugger;
                    this.setState({ load: !this.state.load, modal: false, items: data });

                }).catch((e) => {
                    //console.log(e);
                });
            } else {

            }
        }
    }
    validateForm = () => {

        if (this.state.form.title) {
            //debugger;
            this.setState({ form: { ...this.state.form, error: null } })
            return true
        } else {
            //debugger;
            this.setState({ form: { ...this.state.form, error: "Please Enter Announcement" } })
            return false
        }

    }
    toggleActiveState = (s, i) => {
        ////debugger;
        //console.log(this.state, "current");
        const fdata = this.state.items.filter(item => item.id === i);
        //debugger;
        this.setState({ form: { active: s, title: fdata[0].title, url: fdata[0].url, error: null } });
        //console.log(this.state, "aftertoggle");
        this.updateActiveState(i);
    }
    handleActiveState = (s) => {
        ////debugger;
        //console.log(this.state, "current");
        //debugger;
        this.setState({ form: { ...this.state.form, active: s, error: null } })
        //console.log(this.state, "after");
    }

    handleChange = (e) => {
        ////debugger;
        e.preventDefault()
        e.persist();
        //debugger;
        this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value, error: null } })
        //console.log(this.state);
    }


    handleSave = (e) => {
        e.preventDefault();

        const { firebase, loading } = this.context;
        if (this.validateForm()) {
            if (!loading) {
                //console.log(this.state);
                firebase.db.collection("shoutouts").add({
                    active: this.state.form.active,
                    title: this.state.form.title.toString(),
                    url: this.state.form.url.toString()
                }).then(data => {
                    //console.log(data);
                    //this.toggle();
                    this.afterAdd();
                    //console.log(this.state);
                }).catch(function(e) {
                    //console.log("error");
                    //console.log(e);
                })
            }
        }
    }

    updateActiveState = (s, ann_id) => {
        const { firebase, loading } = this.context;
        ////debugger;
        if (!loading) {
            firebase.db.collection("shoutouts").doc(ann_id).update({
                active: s
            }).then((data) => {
                //console.log(data);
                //this.toggle();
                this.afterAdd("status");
                //console.log(this.state);
            }).catch(function(e) {
                //console.log("error");
                //console.log(e);
            })
        }
    }


    handleEdit = (e) => {
        //debugger;
        e.preventDefault();
        const ann_id = e.currentTarget.getAttribute("editid");
        ////debugger;
        if (this.validateForm()) {
            const { firebase, loading } = this.context;
            if (!loading) {
                firebase.db.collection("shoutouts").doc(ann_id).update({
                    active: this.state.form.active,
                    title: this.state.form.title.toString(),
                    url: this.state.form.url.toString()
                }).then(data => {
                    //console.log(data);
                    //this.toggle();
                    //this.afterAdd("edit");
                    //console.log(this.state);
                    this.setState({ load: !this.state.load, modal: false, edit: false, editid: null, form: { active: true, title: "", url: "", error: null } });
                    //console.log(this.state);
                }).catch(function(e) {
                    //console.log("error");
                    //console.log(e);
                })
            }
        }
    }
    handleUpdate = (e) => {
        e.preventDefault();
        const seldata = this.state.items.filter(item => item.id === e.currentTarget.id);
        //debugger;
        this.setState({ edit: true, modal: true, editid: e.currentTarget.id, form: { active: seldata[0].active, title: seldata[0].title, url: seldata[0].url, error: null } });
        //console.log(this.state);
    }
    handleDelete = (e) => {
        e.preventDefault();
        //console.log(e.target.id);
        const { firebase, loading } = this.context;
        if (!loading) {
            firebase.db.collection("shoutouts").doc(e.target.id).delete().then((data) => {
                //console.log("deleted", data);
                this.setState({ load: !this.state.load });
            }).catch((e) => {
                //console.log("error", e);
            })
        }
    }

    // const listItems = list.allShoutout.edges.map((node, index) => (
    //     <Announcement key={index} special="false" item={node.node}>
    //     </Announcement>
    // ));
    render() {
        const listItems = this.state.items && this.state.items.map((node, index) => (
            <AnnouncementItem key={index} special="false" item={node} toggleActiveState={this.updateActiveState} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate}>
            </AnnouncementItem>
        ));
        return (
            <>
                {this.getlist()}
                <MDBContainer className="mx-4 px-4 mt-3" >
                    <MDBRow >
                        <MDBBtn onClick={this.toggle} className="float-right"> Add Announcement</MDBBtn>
                    </MDBRow>
                    <MDBRow className="border-top border-bottom border-secondary py-2 my-3">
                        <div className="col-md-12">
                            {listItems}
                        </div>
                    </MDBRow>
                </MDBContainer>
                <AddAnnounce
                    modal={this.state.modal}
                    formval={this.state.form}
                    toggle={this.toggle}
                    edit={this.state.edit}
                    editid={this.state.editid}
                    handleEdit={this.handleEdit}
                    handleChange={this.handleChange}
                    handleActiveState={this.handleActiveState}
                    handleSave={this.handleSave} />
            </>

        );
    }
}
export default AnnounceAdminList;