import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBInput } from 'mdbreact';
import ToggleButton from 'react-toggle-button'
import EventImageList from './EventImageList';
const AddEvent = (props) => {
    return (
        <div>
            <MDBModal isOpen={props.modal} toggle={props.toggle}>
                <MDBModalHeader toggle={props.toggle}>{props.edit ? "Edit" : "Add"} Event</MDBModalHeader>
                <MDBModalBody>
                    {/* {console.log(props)} */}
                    <span className="red-text">{props.formval.error ? props.formval.error : ""}</span>
                    <ToggleButton
                        inactiveLabel="OFF"
                        activeLabel="ON"
                        value={props.formval.active}
                        onToggle={(value) => { console.log(value, "toggleval"); props.handleActiveState(!value) }} />

                    <EventImageList handleChange={props.handleEventImageChange} value={props.formval.image} className="mt-1 pt-1" />
                    <MDBInput label="Event Name" onChange={props.handleChange} type="text" name="title" value={props.formval.title} required />
                    <MDBInput label="Start Time" onChange={props.handleChange} type="datetime-local" name="start" value={props.formval.start} min={props.formval.mintime} required />
                    <MDBInput label="End Time" onChange={props.handleChange} type="datetime-local" name="end" value={props.formval.end} min={props.formval.mintime} required />
                    <MDBInput label="Description" onChange={props.handleChange} type="textarea" rows="3" name="description" value={props.formval.description} required />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={props.toggle}>Close</MDBBtn>
                    <MDBBtn color="primary" editid={props.edit ? props.editid : ""} onClick={props.edit ? props.handleEdit : props.handleSave}>Save changes</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>
    );
};

export default AddEvent;