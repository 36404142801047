import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBInput } from 'mdbreact';
import ToggleButton from 'react-toggle-button';
import EventImageList from './EventImageList';

const AddActivity = (props) => {
    const daylist = props.optionList.days.map((option, index) => (
        <option value={option.value} key={index} > {option.text} </option>
    ));
    const freqlist = props.optionList.freq.map((option, index) => (
        <option value={option.value} key={index} > {option.text} </option>
    ));
    return (
        <div>
            <MDBModal isOpen={props.modal} toggle={props.toggle} >
                <MDBModalHeader toggle={props.toggle}>{props.edit ? "Edit" : "Add"}  Event</MDBModalHeader>
                <MDBModalBody style={{ maxHeight: "400px", overflowY: "scroll" }}>
                    <span className="red-text">{props.formval.error ? props.formval.error : ""}</span>
                    <ToggleButton
                        inactiveLabel="OFF"
                        activeLabel="ON"
                        value={props.formval.active}
                        onToggle={(value) => { console.log(value, "toggleval"); props.handleActiveState(!value) }} />

                    <select className="browser-default custom-select mt-3 p-2 " type="text" name="day" value={props.formval.day} onBlur={props.handleChange} onChange={props.handleChange} required>
                        <option>Select Day</option>
                        {daylist}
                    </select>

                    <select className="browser-default custom-select mt-3 p-2" type="text" name="frequency" value={props.formval.frequency} onBlur={props.handleChange} onChange={props.handleChange} required>
                        <option>Select Frequency</option>
                        {freqlist}
                    </select>
                    <EventImageList handleChange={props.handleEventImageChange} value={props.formval.image} className="mt-1 pt-1" />
                    <MDBInput label="Event Name" onChange={props.handleChange} type="text" name="title" value={props.formval.title} required />
                    <MDBInput label="Start Time" onChange={props.handleChange} type="time" name="starttime" value={props.formval.starttime} min={props.formval.mintime} required />
                    <MDBInput label="End Time" onChange={props.handleChange} type="time" name="endtime" value={props.formval.endtime} min={props.formval.mintime} required />
                    <MDBInput label="Description" onChange={props.handleChange} type="textarea" rows="3" name="description" value={props.formval.description} required />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={props.toggle}>Close</MDBBtn>
                    <MDBBtn color="primary" editid={props.edit ? props.editid : ""} onClick={props.edit ? props.handleEdit : props.handleSave}>Save changes</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div >
    );
};

export default AddActivity;