import React from 'react';
import { MDBContainer, MDBRow, MDBBtn } from 'mdbreact';
import '../Announcements/announcement.scss';
import { FirebaseContext } from '../Firebase';
import Event from './Event';
import AddActivity from './AddActivity';
import * as momentf from 'moment';
class ActivityAdminList extends React.Component {
    static contextType = FirebaseContext

    state = {
        modal: false,
        load: true,
        edit: false,
        editid: null,
        form: {
            title: "",
            day: "",
            active: true,
            image: null,
            frequency: "",
            mintime: null,
            starttime: null,
            endtime: null,
            description: ""
        },
        items: null
    }
    componentDidMount() {
        this.setState({ form: { starttime: this.getCurrentTime(), endtime: this.getCurrentTime(1), mintime: this.getCurrentTime() } })
    }
    convertFrom24To12Format = (time24) => {
        const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;

        return `${hours}:${minutes} ${period}`;
    }
    getCurrentTime = (addhr) => {
        const today = new Date();
        if (addhr) {
            return momentf(today).add(addhr, 'hours').format('hh:mm');
        } else {
            return momentf(today).format('hh:mm')
        }
    }
    getTodayDateTime = () => {
        const today = new Date();
        return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T' + today.getHours() + ":" + today.getMinutes();
    }
    getTodayDate = () => {
        const today = new Date();
        return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    }

    getOptionsList() {
        const optionList = {};
        optionList.days = [
            { text: "Monday", value: "Monday" },
            { text: "Tuesday", value: "Tuesday" },
            { text: "Wednesday", value: "Wednesday" },
            { text: "Thursday", value: "Thursday" },
            { text: "Friday", value: "Friday" },
            { text: "Saturday", value: "Saturday" },
            { text: "Sunday", value: "Sunday" }
        ];
        optionList.freq = [
            { text: "Daily", value: "Daily" },
            { text: "Weekly", value: "Weekly" }
        ];
        optionList.active = [
            { text: "Active", value: true },
            { text: "Inactive", value: false }
        ];
        return optionList;

    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
        this.setState({ edit: false, editid: null, form: { ...this.state.form, day: "", active: true, image: null, frequency: "", title: "", description: "", starttime: this.getCurrentTime(), endtime: this.getCurrentTime(1), mintime: this.getCurrentTime() } })
    }

    validateForm = () => {
        if (this.state.form.title && this.state.form.day && this.state.form.frequency) {
            //debugger;
            this.setState({ form: { ...this.state.form, error: null } })
            debugger;
            if (this.state.form.starttime && this.state.form.endtime) {
                this.setState({ form: { ...this.state.form, error: null } })
                return true;
            } else {
                this.setState({ form: { ...this.state.form, error: "Enter both start time and end time" } })
            }

        } else {
            //debugger;
            this.setState({ form: { ...this.state.form, error: "Please Enter all event information" } })
            return false
        }

    }

    afterAdd = (action) => {
        console.log(action);
        if (action === 'delete') {
            this.setState({ load: !this.state.load, modal: !this.state.modal });

        } if (action === 'status') {
            this.setState({ load: !this.state.load, form: { active: true, ...this.state.form, error: null } });
        } else {
            this.setState({ load: !this.state.load, modal: !this.state.modal, form: { title: "", url: "" } });
        }
    }
    getlist = () => {
        const { firebase, loading } = this.context;
        if (!loading) {
            if (this.state.load) {
                firebase.db.collection("activities").get().then(querySnapshot => {
                    //console.log(querySnapshot.docs);
                    const data = querySnapshot.docs.map(doc => {
                        const datadoc = { id: doc.id, ...doc.data() };
                        return datadoc;
                    });
                    this.setState({ load: !this.state.load, items: data });

                }).catch((e) => {
                    console.log(e);
                });
            } else {

            }
        }
    }
    handleEventImageChange = (option) => {
        this.setState({ form: { ...this.state.form, image: option } })
        console.log(this.state);

    }

    handleChange = (e) => {
        //debugger;
        e.preventDefault();
        e.persist();
        e.stopPropagation();
        this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
        //console.log(this.state);
    }

    handleActiveState = (s) => {
        ////debugger;
        //console.log(this.state, "current");
        //debugger;
        this.setState({ form: { ...this.state.form, active: s, error: null } })
        //console.log(this.state, "after");
    }

    handleEdit = (e) => {
        //debugger;
        e.preventDefault();
        const ann_id = e.currentTarget.getAttribute("editid");
        ////debugger;
        if (this.validateForm()) {
            const { firebase, loading } = this.context;
            if (!loading) {
                firebase.db.collection("activities").doc(ann_id).update({
                    active: this.state.form.active,
                    day: this.state.form.day,
                    frequency: this.state.form.frequency,
                    image: this.state.form.image,
                    title: this.state.form.title.toString(),
                    starttime: this.convertFrom24To12Format(this.state.form.starttime),
                    endtime: this.convertFrom24To12Format(this.state.form.endtime),
                    description: this.state.form.description.toString()
                }).then(data => {
                    //console.log(data);
                    //this.toggle();
                    //this.afterAdd("edit");
                    //console.log(this.state);
                    this.setState({ load: !this.state.load, modal: false, edit: false, editid: null, form: { ...this.state.form, active: true, day: "", frequency: "", title: "", description: "", error: null } });
                    //console.log(this.state);
                }).catch(function (e) {
                    //console.log("error");
                    //console.log(e);
                })
            }
        }
    }

    updateActiveState = (s, ann_id) => {
        const { firebase, loading } = this.context;
        ////debugger;
        if (!loading) {
            firebase.db.collection("activities").doc(ann_id).update({
                active: s
            }).then((data) => {
                //console.log(data);
                //this.toggle();
                this.afterAdd("status");
                //console.log(this.state);
            }).catch(function (e) {
                //console.log("error");
                //console.log(e);
            })
        }
    }
    handleUpdate = (e) => {
        debugger;
        e.preventDefault();
        const seldata = this.state.items.filter(item => item.id === e.currentTarget.id);
        this.setState({
            edit: true, modal: true, editid: e.currentTarget.id, form: {
                active: seldata[0].active,
                day: seldata[0].day,
                frequency: seldata[0].frequency,
                title: seldata[0].title,
                image: seldata[0].image,
                starttime: momentf(seldata[0].starttime, ["h:mm A"]).format("HH:mm"),
                endtime: momentf(seldata[0].endtime, ["h:mm A"]).format("HH:mm"),
                description: seldata[0].description,
                error: null
            }
        });
        console.log(this.state);
    }

    handleSave = (e) => {
        e.preventDefault();
        const { firebase, loading } = this.context;

        if (!loading) {
            // console.log(this.state);
            // console.log(this.convertFrom24To12Format(this.state.form.start));
            // console.log(this.convertFrom24To12Format(this.state.form.end));
            // debugger;
            if (this.validateForm()) {
                console.log(this.state);
                firebase.db.collection("activities").add({
                    active: this.state.form.active,
                    day: this.state.form.day,
                    frequency: this.state.form.frequency,
                    image: this.state.form.image,
                    title: this.state.form.title.toString(),
                    starttime: this.convertFrom24To12Format(this.state.form.starttime),
                    endtime: this.convertFrom24To12Format(this.state.form.endtime),
                    description: this.state.form.description.toString()
                }).then(data => {
                    //console.log(data);
                    //this.toggle();
                    this.afterAdd();
                    // this.setState({ load: !this.state.load, modal: !this.state.modal, form: { title: "", url: "" } });
                    //console.log(this.state);
                }).catch(function (e) {
                    console.log("error");
                    console.log(e);
                })
            }
        }
    }
    handleDelete = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        const { firebase, loading } = this.context;
        if (!loading) {
            firebase.db.collection("activities").doc(e.target.id).delete().then((data) => {
                console.log("deleted", data);
                this.afterAdd("delete");
            }).catch((e) => {
                console.log("error", e);
            })
        }
    }

    // const listItems = list.allShoutout.edges.map((node, index) => (
    //     <Announcement key={index} special="false" item={node.node}>
    //     </Announcement>
    // ));
    render() {
        const listItems = this.state.items && this.state.items.map((node, index) => (
            <Event key={index} special="false" node={node} toggleActiveState={this.updateActiveState} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} fullpage admin>
            </Event>
        ));
        return (
            <>
                {this.getlist()}
                <MDBContainer className="mx-4 px-4 mt-3" >
                    <MDBRow >
                        <MDBBtn onClick={this.toggle} className="float-right"> Add Activity</MDBBtn>
                    </MDBRow>
                    <MDBRow className="border-top border-bottom border-secondary py-2 my-3">
                        <div className="col-md-12">
                            {listItems}
                        </div>
                    </MDBRow>
                </MDBContainer>
                <AddActivity
                    modal={this.state.modal}
                    formval={this.state.form}
                    optionList={this.getOptionsList()}
                    toggle={this.toggle}
                    edit={this.state.edit}
                    editid={this.state.editid}
                    handleEdit={this.handleEdit}
                    handleChange={this.handleChange}
                    handleActiveState={this.handleActiveState}
                    handleEventImageChange={this.handleEventImageChange}
                    handleSave={this.handleSave} />
            </>

        );
    }
}
export default ActivityAdminList;