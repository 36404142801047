import React from 'react';
import { MDBBtn } from 'mdbreact';
import ToggleButton from 'react-toggle-button'
const AnnouncementItem = (props) => {
    const linkedurl = <a href={props.item.url} className="font-weight-normal text-capitalize text-danger">Link</a>;

    return (
        <div className="card my-2 p-2">
            <span className="font-weight-bold px-1 text-secondary"  >
                {props.item.title}  {props.item.url ? linkedurl : ""}




            </span>
            <div>
                <MDBBtn size="xs" className="float-right" color="danger" id={props.item.id} onClick={props.handleDelete}>Delete</MDBBtn>
                <MDBBtn size="xs" className="float-right" color="primary" id={props.item.id} onClick={props.handleUpdate}>Edit</MDBBtn>
                <div className="float-right" style={{ padding: "15px 5px" }}>
                    <ToggleButton
                        className="float-right"
                        inactiveLabel="OFF"
                        activeLabel="ON"
                        value={props.item.active}
                        onToggle={(value) => { console.log(value, "toggleitem"); props.toggleActiveState(!value, props.item.id) }} /> </div>
            </div>
        </div>

    );
};

export default AnnouncementItem;