import React from 'react';
import { MDBContainer, MDBRow, MDBBtn } from 'mdbreact';
import '../Announcements/announcement.scss';
import { FirebaseContext } from '../Firebase';
import AddEvent from './AddEvent';
import Event from './Event';
import * as momentf from 'moment';
class EventAdminList extends React.Component {
    static contextType = FirebaseContext

    state = {
        modal: false,
        load: true,
        form: {
            active: true,
            image: null,
            title: "",
            mintime: null,
            start: null,
            end: null,
            description: ""
        },
        events: null
    }
    componentDidMount() {
        this.setState({ form: { ...this.state.form, start: this.getTodayDateTime(), end: this.getTodayDateTime(1), mintime: this.getTodayDateTime() } })
    }
    getTodayDateTime = (addhr) => {
        const today = new Date();
        //console.log(momentf(today).format('YYYY-MM-DDThh:mm:ss'))
        if (addhr) {
            return momentf(today).add(addhr, 'hours').format('YYYY-MM-DDThh:mm');
        } else {
            return momentf(today).format('YYYY-MM-DDThh:mm');
        }

    }
    getTodayDate = () => {
        const today = new Date();
        return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    }

    toggle = () => {
        const editstate = this.state.editid ? false : false;
        this.setState({
            modal: !this.state.modal
        });
        this.setState({ edit: editstate, editid: null, form: { active: true, image: null, title: "", description: "", start: this.getTodayDateTime(), end: this.getTodayDateTime(1), mintime: this.getTodayDateTime(), error: null } })
    }

    afterAdd = (action) => {
        console.log(action);
        if (action === 'delete') {
            this.setState({ load: !this.state.load, modal: !this.state.modal });

        } if (action === 'status') {
            this.setState({ load: !this.state.load, form: { active: true, ...this.state.form, error: null } });
        } else {
            this.setState({ load: !this.state.load, modal: !this.state.modal, form: { title: "", url: "" } });
        }
    }
    getlist = () => {
        const { firebase, loading } = this.context;
        if (!loading) {
            if (this.state.load) {
                firebase.db.collection("events").orderBy('start', 'asc').orderBy('active', 'asc').get().then(querySnapshot => {
                    //console.log(querySnapshot.docs);
                    const data = querySnapshot.docs.map(doc => {
                        const datadoc = { id: doc.id, ...doc.data() };
                        return datadoc;
                    });
                    //debugger;
                    this.setState({ load: !this.state.load, events: data });

                }).catch((e) => {
                    console.log(e);
                });
            } else {

            }
        }
    }
    validateForm = () => {
        if (this.state.form.title) {
            //debugger;
            this.setState({ form: { ...this.state.form, error: null } })
            if (this.state.form.start < this.state.form.end) {
                this.setState({ form: { ...this.state.form, error: null } })
                return true;
            } else {
                this.setState({ form: { ...this.state.form, error: "End time should be later than start time" } })
            }

        } else {
            //debugger;
            this.setState({ form: { ...this.state.form, error: "Please Enter Event Title" } })
            return false
        }

    }
    handleEventImageChange = (option) => {
        this.setState({ form: { ...this.state.form, image: option } })

        console.log(this.state);

    }
    handleChange = (e) => {
        e.preventDefault();
        e.persist();
        e.stopPropagation();

        this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
        console.log(this.state);
        //debugger;
        console.log(momentf);
    }

    handleActiveState = (s) => {
        ////debugger;
        //console.log(this.state, "current");
        //debugger;
        this.setState({ form: { ...this.state.form, active: s, error: null } })
        //console.log(this.state, "after");
    }

    updateActiveState = (s, ann_id) => {
        const { firebase, loading } = this.context;
        ////debugger;
        if (!loading) {
            firebase.db.collection("events").doc(ann_id).update({
                active: s
            }).then((data) => {
                //console.log(data);
                //this.toggle();
                this.afterAdd("status");
                //console.log(this.state);
            }).catch(function (e) {
                //console.log("error");
                //console.log(e);
            })
        }
    }

    handleUpdate = (e) => {
        e.preventDefault();
        const seldata = this.state.events.filter(item => item.id === e.currentTarget.id);
        this.setState({
            edit: true, modal: true, editid: e.currentTarget.id, form: {
                active: seldata[0].active,
                title: seldata[0].title,
                image: seldata[0].image,
                start: momentf(new Date(seldata[0].start.seconds * 1000)).format('YYYY-MM-DDTHH:mm'),
                end: momentf(new Date(seldata[0].end.seconds * 1000)).format('YYYY-MM-DDTHH:mm'),
                description: seldata[0].description,
                error: null
            }
        });
        console.log(this.state);
    }

    handleEdit = (e) => {
        //debugger;
        e.preventDefault();
        const ann_id = e.currentTarget.getAttribute("editid");
        ////debugger;
        if (this.validateForm()) {
            const { firebase, loading } = this.context;
            if (!loading) {
                firebase.db.collection("events").doc(ann_id).update({
                    active: this.state.form.active,
                    image: this.state.form.image,
                    title: this.state.form.title.toString(),
                    start: new Date(this.state.form.start),
                    end: new Date(this.state.form.end),
                    description: this.state.form.description.toString()
                }).then(data => {
                    //console.log(data);
                    //this.toggle();
                    //this.afterAdd("edit");
                    //console.log(this.state);
                    this.setState({ load: !this.state.load, modal: false, edit: false, editid: null, form: { active: true, title: "", url: "", error: null } });
                    //console.log(this.state);
                }).catch(function (e) {
                    //console.log("error");
                    //console.log(e);
                })
            }
        }
    }

    handleSave = (e) => {
        e.preventDefault();
        const { firebase, loading } = this.context;

        if (!loading) {
            console.log(this.state);
            if (this.validateForm()) {
                firebase.db.collection("events").add({
                    active: this.state.form.active,
                    image: this.state.form.image,
                    title: this.state.form.title.toString(),
                    start: new Date(this.state.form.start),
                    end: new Date(this.state.form.end),
                    description: this.state.form.description.toString()
                }).then(data => {
                    console.log(data);
                    //this.toggle();
                    this.afterAdd();
                    // this.setState({ load: !this.state.load, modal: !this.state.modal, form: { title: "", url: "" } });
                    console.log(this.state);
                }).catch(function (e) {
                    console.log("error");
                    console.log(e);
                })
            }
        }
    }
    handleDelete = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        const { firebase, loading } = this.context;
        if (!loading) {
            firebase.db.collection("events").doc(e.target.id).delete().then((data) => {
                console.log("deleted", data);
                this.afterAdd("delete");
            }).catch((e) => {
                console.log("error", e);
            })
        }
    }

    // const listItems = list.allShoutout.edges.map((node, index) => (
    //     <Announcement key={index} special="false" item={node.node}>
    //     </Announcement>
    // ));
    render() {
        const listItems = this.state.events && this.state.events.map((node, index) => (
            <Event key={index} special="true" node={node} toggleActiveState={this.updateActiveState} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} fullpage admin>
            </Event>
        ));
        return (
            <>
                {this.getlist()}
                <MDBContainer className="mx-4 px-4 mt-3" >
                    <MDBRow >
                        <MDBBtn onClick={this.toggle} className="float-right"> Add Event</MDBBtn>
                    </MDBRow>
                    <MDBRow className="border-top border-bottom border-secondary py-2 my-3">
                        <div className="col-md-12">
                            {listItems}
                        </div>
                    </MDBRow>
                </MDBContainer>
                <AddEvent
                    modal={this.state.modal}
                    formval={this.state.form}
                    toggle={this.toggle}
                    edit={this.state.edit}
                    editid={this.state.editid}
                    handleEdit={this.handleEdit}
                    handleChange={this.handleChange}
                    handleEventImageChange={this.handleEventImageChange}
                    handleActiveState={this.handleActiveState}
                    handleSave={this.handleSave} />
            </>

        );
    }
}
export default EventAdminList;