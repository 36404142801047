import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBBtn, MDBInput } from 'mdbreact';
import { FirebaseContext } from '../Firebase';

class LiveItemInfo extends Component {
    static contextType = FirebaseContext

    state = {
        load: true,
        edit: true,
        formval: {
            id: "",
            title: "",
            url: ""
        }
    }

    toggle = () => {
        this.setState({ edit: !this.state.edit });
    }
    getLiveUrl = () => {
        const { firebase, loading } = this.context;
        if (!loading) {
            if (this.state.load) {
                firebase.db.collection("livestream").get().then(querySnapshot => {
                    //console.log(querySnapshot.docs);
                    debugger;
                    const data = querySnapshot.docs.map(doc => {
                        const datadoc = { id: doc.id, ...doc.data() };
                        return datadoc;
                    });
                    debugger;
                    this.setState({ load: !this.state.load, formval: { id: data[0].id, title: data[0].title, url: data[0].url } });

                }).catch((e) => {
                    //console.log(e);
                });
            } else {

            }
        }
    }

    handleChange = (e) => {
        ////debugger;
        e.preventDefault()
        e.persist();
        //debugger;
        this.setState({ formval: { ...this.state.formval, [e.target.name]: e.target.value } })
        console.log(this.state);
    }

    handleSave = () => {
        debugger;
        this.toggle();
        const { firebase, loading } = this.context;
        if (!loading) {
            firebase.db.collection("livestream").doc(this.state.formval.id).update({
                title: this.state.formval.title.toString(),
                url: this.state.formval.url.toString()
            }).then(data => {
                //console.log(data);
                //this.toggle();
                //this.afterAdd("edit");
                //console.log(this.state);
                this.setState({ load: true, edit: true });
                //console.log(this.state);
            }).catch(function (e) {
                //console.log("error");
                console.log(e);
            })
        }

    }


    render() {
        return (
            <>
                {this.getLiveUrl()}
                <MDBContainer className="mx-4 px-4 mt-3" >
                    <MDBRow >
                        <h4 onClick={this.toggle} className="float-right"> Update Live URL</h4>
                    </MDBRow>
                    <MDBRow className="px-2 py-2 font-weight-bold text-secondary"> 
                    //"border-top border-bottom border-secondary py-2 my-3">
                        <div className="col-md-12">
                            <MDBInput label="Title" onChange={this.handleChange} type="text" name="title" value={this.state.formval.title} disabled={this.state.edit} required />
                            <MDBInput label="link" onChange={this.handleChange} type="text" name="url" value={this.state.formval.url} disabled={this.state.edit} required />
                        </div>
                        <MDBBtn color="primary" onClick={this.toggle}>Edit</MDBBtn>
                        <MDBBtn color="primary" onClick={this.handleSave} disabled={this.state.edit} >Save</MDBBtn>
                    </MDBRow>
                </MDBContainer>
            </>
        );
    }
}

export default LiveItemInfo;