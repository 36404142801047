import React from 'react';
//import Layout from '../../components/layout';
import { Link } from "gatsby"
import { MDBContainer, MDBRow, MDBCol, MDBNav, MDBNavItem, MDBTabContent, MDBTabPane } from "mdbreact";
import './index.css'; //Import here your file style
import ProtectedRoute from '../../Utils/protectedRoute';
import AnnounceAdminList from '../../components/Announcements/AnnounceAdminList';
import EventAdminList from '../../components/Events/EventAdminList';
import ActivityAdminList from '../../components/Events/ActivityAdminList';
import SliderItemList from '../../components/Slider/SliderItemList';
import LiveItemInfo from '../../components/LiveStream/LiveItemInfo';

class AdminIndex extends React.Component {
    state = {
        items: {
            default: "1",
        }
    };

    togglePills = (type, tab) => e => {
        e.preventDefault();
        if (this.state.items[type] !== tab) {
            let items = { ...this.state.items };
            items[type] = tab;
            this.setState({
                items
            });
        }
    };
    render() {
        return (
            <>
                <ProtectedRoute />
                <MDBContainer >
                    <MDBRow>
                        <MDBCol md="3" className="leftTab leftPanel ">
                            <MDBNav tabs>
                                <MDBNavItem className="w-100">
                                    <Link to="/admin/#!" className="nav-link " activeClassName="active" active={this.state.items["default"] === "1"} onClick={this.togglePills("default", "1")}>
                                        Announcements
                                    </Link>

                                </MDBNavItem>
                                <MDBNavItem className="w-100">
                                    <Link to="/admin/#!" className="nav-link" activeClassName="active" active={this.state.items["default"] === "2"} onClick={this.togglePills("default", "2")}>
                                        Special Events
                                    </Link>

                                </MDBNavItem>
                                <MDBNavItem className="w-100">
                                    <Link to="/admin/#!" className="nav-link" activeClassName="active" active={this.state.items["default"] === "3"} onClick={this.togglePills("default", "3")}>
                                        Daily Activities
                                    </Link>
                                </MDBNavItem>
                                <MDBNavItem className="w-100">
                                    <Link to="/admin/#!" className="nav-link" activeClassName="active" active={this.state.items["default"] === "4"} onClick={this.togglePills("default", "4")}>
                                        Banners
                                    </Link>
                                </MDBNavItem>
                                <MDBNavItem className="w-100">
                                    <Link to="/admin/#!" className="nav-link" activeClassName="active" active={this.state.items["default"] === "5"} onClick={this.togglePills("default", "5")}>
                                        Update Live url
                                    </Link>
                                </MDBNavItem>

                            </MDBNav>
                        </MDBCol>
                        <MDBCol md="8" className="rightTab rightPanel ">
                            <MDBTabContent activeItem={this.state.items["default"]}>
                                <MDBTabPane tabId="1">
                                    <AnnounceAdminList />
                                </MDBTabPane>
                                <MDBTabPane tabId="2">
                                    <EventAdminList />
                                </MDBTabPane>
                                <MDBTabPane tabId="3">
                                    <ActivityAdminList />
                                </MDBTabPane>
                                <MDBTabPane tabId="4">

                                    <SliderItemList />

                                </MDBTabPane>

                                <MDBTabPane tabId="5">

                                    <LiveItemInfo />

                                </MDBTabPane>
                            </MDBTabContent>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

            </>
        );
    }
}

export default AdminIndex;