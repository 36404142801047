import React from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import { useStaticQuery, graphql } from 'gatsby';
const EventImageList = (props) => {
    const list = useStaticQuery(graphql`
    {
        allEventimageJson {
            nodes {
              label
              value
            }
          }
    }`);;
    const nodeData = list.allEventimageJson.nodes;
    const { Option } = components;
    const CustomOption = (props) => (
        <Option {...props} >
            <img src={props.data.value} height="40px" className="m-1" />
            <span className="px-1">{props.data.label}</span>
        </Option>);

    return (
        <div className="mt-1 pt-2">
            <Select
                placeholder="Select Event Image"
                options={nodeData}
                onChange={props.handleChange}
                value={props.value}
                components={{ Option: CustomOption }} />
        </div>
    );
};

export default EventImageList;