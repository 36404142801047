import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBInput } from 'mdbreact';

const AddImage = (props) => {
    return (
        <div>
            <MDBModal isOpen={props.modal} toggle={props.toggle}>
                <MDBModalHeader toggle={props.toggle}>{props.edit ? "Edit" : "Add"} Banner</MDBModalHeader>
                <MDBModalBody>
                    <span className="red-text">{props.formval.error ? props.formval.error : ""}</span>
                    {props.progress > 0 ?
                        <p className="green-text"> image upload is under progress {props.progress} % complete </p> : ""}
                    <MDBInput onChange={props.handleImageAsFile} type="file" name="file" accept="image/jpg, image/gif,image/jpeg,image/png" required className="border border-0 border-dark" />
                    <p className="red-text">*Please upload image having size less than 3MB</p>
                    <MDBInput label="Title" onChange={props.handleChange} type="text" name="title" value={props.formval.title} required />

                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={props.toggle}>Close</MDBBtn>
                    <MDBBtn color="primary" editid={props.edit ? props.editid : ""} onClick={props.edit ? props.handleEdit : props.handleSave}>Save changes</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>
    );
};

export default AddImage;