import React, { Component } from 'react';
import SliderItem from './SliderItem';
import { MDBContainer, MDBRow, MDBBtn } from 'mdbreact';
import AddImage from './addImage';
import { FirebaseContext } from '../Firebase';

class SliderItemList extends Component {
    static contextType = FirebaseContext

    state = {
        modal: false,
        load: true,
        edit: false,
        editid: null,
        progress: 0,
        form: {
            active: true,
            file: null,
            title: "",
            error: null
        },
        items: null
    }
    toggle = () => {
        //debugger;
        const editstate = this.state.editid ? false : false;
        this.setState({
            modal: !this.state.modal,
            edit: editstate,
            editid: null,
            form: {
                active: this.state.form.active,
                title: "",
                url: "",
                error: null
            }
        });
        //console.log(this.state);
    }

    afterAdd = (action) => {
        //debugger;
        //console.log(action);
        //console.log(this.state);
        if (action === "delete") {
            this.setState({ load: !this.state.load });
        } if (action === "edit") {
            //console.log(this.state);
            this.setState({ load: !this.state.load, modal: false, edit: false, editid: null, form: { active: true, title: "", file: null, error: null } });
            //console.log(this.state);
        } if (action === "status") {
            this.setState({ load: !this.state.load, edit: false, editid: null, form: { active: true, title: "", file: null, error: null } });
        }
        else {
            this.setState({ load: !this.state.load, modal: !this.state.modal, progress: 0, edit: false, editid: null, form: { active: true, title: "", file: null, error: null } });
        }
    }

    getimages = () => {
        const { firebase, loading } = this.context;
        if (!loading) {
            if (this.state.load) {
                let storageRef = firebase.storage.ref("banners");
                var self = this;
                storageRef.listAll().then(function (data) {
                    const urls = data.items;
                    console.log(self.state);
                    const tarray = urls.map(async (itemRef) => {
                        const url = await itemRef.getDownloadURL().then(u => u)
                        return url.toString();
                    })
                    Promise.all(tarray).then(function (data) {
                        self.setState({ load: !self.state.load, items: data });
                        console.log(data);

                    });
                })
            }
        }
    }


    validateForm = () => {

        if (this.state.form.file) {
            if (this.state.form.file.size > 3000000) {
                this.setState({ form: { ...this.state.form, error: "Size of file is greater than 3 MB" } })
                return false

            } else {
                this.setState({ form: { ...this.state.form, error: null } })
                return true
            }

        } else {
            //debugger;
            this.setState({ form: { ...this.state.form, error: "Please Enter Announcement" } })
            return false
        }

    }
    toggleActiveState = (s, i) => {
        ////debugger;
        //console.log(this.state, "current");
        const fdata = this.state.items.filter(item => item.id === i);
        //debugger;
        this.setState({ form: { active: s, title: fdata[0].title, url: fdata[0].url, error: null } });
        //console.log(this.state, "aftertoggle");
        this.updateActiveState(i);
    }
    handleActiveState = (s) => {
        ////debugger;
        //console.log(this.state, "current");
        //debugger;
        this.setState({ form: { ...this.state.form, active: s, error: null } })
        //console.log(this.state, "after");
    }

    handleChange = (e) => {
        ////debugger;
        e.preventDefault()
        e.persist();
        //debugger;
        this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value, error: null } })
        console.log(this.state.form);
    }

    handleImageAsFile = (e) => {
        const image = e.target.files[0]
        this.setState({ form: { ...this.state.form, file: image } });
    }


    handleSave = (e) => {
        e.preventDefault();

        const { firebase, loading } = this.context;
        if (this.validateForm()) {
            if (!loading) {
                console.log(this.state.form);
                let storageRef = firebase.storage.ref("banners");
                const mainImage = storageRef.child(this.state.form.file.name);
                const uploadTask = mainImage.put(this.state.form.file)
                uploadTask.on('state_changed', (snapshot) => {

                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({ progress: progress });
                },
                    error => {
                        // Error function ...
                        console.log(error);
                    },
                    () => {
                        // complete function ...
                        storageRef.child(this.state.form.file.name)
                            .getDownloadURL()
                            .then(url => {
                                console.log(url);
                                this.afterAdd();
                            });
                    }
                )
                //console.log(this.state);
                // firebase.db.collection("shoutouts").add({
                //     active: this.state.form.active,
                //     title: this.state.form.title.toString(),
                //     url: this.state.form.url.toString()
                // }).then(data => {
                //     //console.log(data);
                //     //this.toggle();
                //     this.afterAdd();
                //     //console.log(this.state);
                // }).catch(function (e) {
                //     //console.log("error");
                //     //console.log(e);
                // })
            }
        }
    }

    updateActiveState = (s, ann_id) => {
        const { firebase, loading } = this.context;
        ////debugger;
        if (!loading) {
            // firebase.db.collection("shoutouts").doc(ann_id).update({
            //     active: s
            // }).then((data) => {
            //     //console.log(data);
            //     //this.toggle();
            //     this.afterAdd("status");
            //     //console.log(this.state);
            // }).catch(function (e) {
            //     //console.log("error");
            //     //console.log(e);
            // })
        }
    }




    handleDelete = (e) => {
        e.preventDefault();
        //console.log(e.target.id);
        const { firebase, loading } = this.context;
        if (!loading) {
            let storageRef = firebase.storage.ref("banners");
            var self = this;
            const imagename = e.target.id.split("%2F").slice(-1).toString().split("?")[0];
            //debugger;
            storageRef.child(imagename).delete().then((data) => {
                //console.log("deleted", data);
                self.setState({ load: !this.state.load });
            }).catch((e) => {
                //console.log("error", e);
            })
        }
    }

    render() {
        const listItems = this.state.items && this.state.items.map((node, index) => (
            <SliderItem key={index} special="false" item={node} toggleActiveState={this.updateActiveState} handleDelete={this.handleDelete} >
            </SliderItem>
        ));
        return (
            <>
                {this.getimages()}
                <div>
                    <MDBContainer className="mx-4 px-4 mt-3" >
                        <MDBRow >

                            <MDBBtn onClick={this.toggle} className="float-right"> Add Banner</MDBBtn>
                        </MDBRow>
                        <MDBRow className="border-top border-bottom border-secondary py-2 my-3">
                            <div className="col-md-12">
                                {listItems}
                            </div>
                        </MDBRow>
                    </MDBContainer>
                    <AddImage
                        modal={this.state.modal}
                        formval={this.state.form}
                        toggle={this.toggle}
                        handleChange={this.handleChange}
                        handleImageAsFile={this.handleImageAsFile}
                        handleSave={this.handleSave}
                        progress={this.state.progress}
                    />
                </div>
            </>
        );
    }
}

export default SliderItemList;