import React from 'react';
import { FirebaseContext } from '../components/Firebase';
import { navigate } from 'gatsby';
const ProtectedRoute = (props) => {
    const { user, loading } = React.useContext(FirebaseContext);
    //console.log("inside protected");


    if (!loading & !user) {
        console.log("ican redirect")
        navigate('/login');
    }

    return (
        <>
            {props.children}

        </>
    );
};

export default ProtectedRoute;