import React from 'react';
import { MDBBtn, MDBCardImage, MDBCardBody } from 'mdbreact';

const SliderItem = (props) => {
    return (
        <div>
            <div className="card my-2 p-2">
                <span className="font-weight-bold px-1 text-secondary"  >
                    {console.log(props)}
                    <MDBCardImage className="img-fluid" src={props.item} waves />
                    <MDBCardBody>
                        <MDBBtn size="xs" className="float-right" color="danger" id={props.item} onClick={props.handleDelete}>Delete</MDBBtn>
                        {/* <MDBBtn size="xs" className="float-right" color="primary" id={props.item.id} onClick={props.handleUpdate}>Edit</MDBBtn> */}
                    </MDBCardBody>

                </span>
            </div>
        </div>
    );
};

export default SliderItem;