import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBInput } from 'mdbreact';
import ToggleButton from 'react-toggle-button'

const AddAnnounce = (props) => {
    return (
        <div>
            <MDBModal isOpen={props.modal} toggle={props.toggle}>
                <MDBModalHeader toggle={props.toggle}>{props.edit ? "Edit" : "Add"} Announcement</MDBModalHeader>
                <MDBModalBody>
                    <span className="red-text">{props.formval.error ? props.formval.error : ""}</span>
                    <ToggleButton
                        inactiveLabel="OFF"
                        activeLabel="ON"
                        value={props.formval.active}
                        onToggle={(value) => { console.log(value, "toggleval"); props.handleActiveState(!value) }} />
                    <MDBInput label="Announcement" onChange={props.handleChange} type="text" name="title" value={props.formval.title} required />
                    <MDBInput label="link" onChange={props.handleChange} type="text" name="url" value={props.formval.url} required />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={props.toggle}>Close</MDBBtn>
                    <MDBBtn color="primary" editid={props.edit ? props.editid : ""} onClick={props.edit ? props.handleEdit : props.handleSave}>Save changes</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>
    );
};

export default AddAnnounce;